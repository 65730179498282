import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import { useLanguage } from "../../hooks/useLanguage";
import { api } from "../../api";
import { useParams } from "react-router-dom";
import { Box, Flex } from "reflexbox";
import { H2 } from "../../components/h2";
import { useTranslation } from "react-i18next";
import { Container } from "../../components/container";

function BlogTitleAndAuthor({ blog }) {
  const { t } = useTranslation("common");

  return (
    <>
      {blog && (
        <>
          <Box style={{ fontWeight: "bold", fontSize: "16pt" }}>{blog.title}</Box>
          <Box color="grey" mt="8px">
            {t("By")} {blog.author}
          </Box>
        </>
      )}
    </>
  );
}

export default () => {
  const { blogId } = useParams();

  const language = useLanguage();
  const { t } = useTranslation("common");

  const [blog, setBlog] = useState();

  useEffect(() => {
    api.get(`/${language}/blogs/articles/${blogId}`).then((res) => {
      const result = res.data.blogArticle;
      setBlog(result);
    });
  }, [language, blogId]);

  return (
    <>
      <Navbar />
      <H2 title={t("Blogs")} rightSide={true} mb={["32px", "72px"]} />
      {blog && (
        <Container mb={["32px", "96px"]}>
          <Flex justifyContent={["", "space-between"]} flexDirection={["column", "row"]} mb={["", "32px"]}>
            <Box>
              <BlogTitleAndAuthor blog={blog} />
            </Box>
            <Box alignSelf={["flex-end", "flex-start"]} my={["16px", "0px"]} color="grey">
              {t("Posted")}{" "}
              {new Intl.DateTimeFormat(language, {
                year: "numeric",
                month: "long",
                day: "numeric"
              }).format(Date.parse(blog.publishedAt))}
            </Box>
          </Flex>
          <Box overflowX={["hidden", ""]}>
            <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
          </Box>
        </Container>
      )}
    </>
  );
};
