import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import { api } from "../../api";
import { Box, Flex } from "reflexbox";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useLanguage } from "../../hooks/useLanguage";
import { Container } from "../../components/container";

const useStyles = makeStyles({
  hoverContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.4)",
    transition: "0.5s ease"
  }
});

function Application(props) {
  const classes = useStyles();

  const { application } = props;

  const [hover, setHover] = useState(false);

  return (
    <Box mb={["16px", "64px"]} style={{ position: "relative" }}>
      <Link to={`/applications/${application.id}`} style={{ textDecoration: "none" }}>
        <Box sx={{ height: ["200px", "600px"], overflowY: "hidden" }}>
          <img
            src={application.image.src}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt={application.title}
            title={application.title}
          />
        </Box>
        {hover && (
          <Box className={classes.hoverContainer} sx={{ width: ["100%", "50%"] }}>
            <Flex
              sx={{
                flex: "1",
                color: "transparent",
                fontWeight: "bold",
                WebkitTextStroke: "2px white",
                fontSize: ["24pt", "36pt"],
                textAlign: "center"
              }}
              justifyContent="flex-end"
              px="48px"
            >
              {application.title}
            </Flex>
            <div style={{ height: "100%", overflow: "hidden" }}>
              <img src="/assets/double-right-arrow.svg" height="100%" alt="" />
            </div>
          </Box>
        )}
        <div
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onTouchStart={() => {
            setHover(true);
          }}
          onTouchEnd={() => {
            setHover(false);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        />
      </Link>
    </Box>
  );
}

export default () => {
  const [categories, setCategories] = useState([]);

  const language = useLanguage();

  useEffect(() => {
    api.get(`/${language}/categories`, { params: { parent: "748971" } }).then((res) => {
      setCategories(res.data.reverse());
    });
  }, [language]);

  return (
    <>
      <Navbar color="black" />
      <Flex flexDirection="column" mb={["32px", "128px"]}>
        <Container>
          {categories.map((category, index) => (
            <Application key={index} application={category} />
          ))}
        </Container>
      </Flex>
    </>
  );
};
