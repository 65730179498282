import { useEffect, useState } from "react";
import { api } from "../api";
import { useLanguage } from "./useLanguage";

export function useSortedBrands() {
  const language = useLanguage();

  const [brands, setBrands] = useState([]);
  const [brandSort, setBrandSort] = useState([]);
  const [sortedBrands, setSortedBrands] = useState([]);

  useEffect(() => {
    api.get(`/${language}/brands?limit=250`).then((res) => {
      const brands = res.data;
      setBrands(brands);
    });
  }, [language]);

  useEffect(() => {
    api.get("/us/textpages/467049").then((res) => {
      const data = res.data.textpage;
      const re = /<li>(.*?)<\/li>/;
      const reStartingTag = /^<li>/;
      const reEndingTag = /<\/li>$/;

      setBrandSort(
        data.content
          .split("\n")
          .filter((x) => x.match(re))
          .map((x) => x.replace(reStartingTag, "").replace(reEndingTag, ""))
      );
    });
  }, []);

  useEffect(() => {
    if (brands.length > 0 && brandSort.length > 0) {
      let newArray = [];
      for (const sorted of brandSort) {
        for (const brand of brands) {
          if (sorted.toLowerCase() === brand.title.toLowerCase()) {
            newArray.push(brand);
          }
        }
      }

      // newArray = newArray.concat(brands); // merge two arrays
      const map = new Map();
      for (const tag of newArray) {
        map.set(tag.id, tag);
      }
      const final = [...map.values()];

      setSortedBrands(final);
    }
  }, [brands, brandSort]);

  return sortedBrands;
}
