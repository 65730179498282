import React, { useState } from "react";
import { Navbar } from "../../components/navbar";
import { Flex, Box } from "reflexbox";
import { Button, makeStyles, TextField, TextareaAutosize } from "@material-ui/core";
import { useTheme } from "emotion-theming";
import { H2 } from "../../components/h2";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Container } from "../../components/container";
import { api } from "../../api";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
  a: {
    color: ({ theme }) => theme.colors.primary,
    fontWeight: "bold"
  },
  noUnderline: {
    textDecoration: "none"
  }
});

const defaultFormState = {
  name: "",
  company: "",
  email: "",
  phone: "",
  subject: "",
  message: ""
};

function EmailingForm() {
  const theme = useTheme();

  const { t } = useTranslation("contact");

  const [form, setForm] = useState(defaultFormState);

  const [error, setError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .post("/mailer", { ...form, toAddress: "info@lumigroup.com" })
      .then((res) => {
        setForm(defaultFormState);
      })
      .catch((err) => {
        setError(true);
      });
  };

  const handleChange = (event) => {
    if (error) {
      setError(false);
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Flex flexDirection={["column", "row"]}>
        <Flex flex={["", "5"]} flexDirection="column" sx={{ mr: ["", "48px"] }}>
          <TextField
            label={t("drop-us-a-line.contact-form.Contact name")}
            name="name"
            value={form.name}
            onChange={handleChange}
            required={true}
          />
          <TextField
            label={t("drop-us-a-line.contact-form.Company")}
            name="company"
            value={form.company}
            onChange={handleChange}
            required={true}
          />
          <TextField
            label={t("drop-us-a-line.contact-form.Email address")}
            name="email"
            type="email"
            value={form.email}
            onChange={handleChange}
            required={true}
          />
          <TextField
            label={t("drop-us-a-line.contact-form.Phone number")}
            name="phone"
            type="tel"
            value={form.phone}
            onChange={handleChange}
            required={true}
          />
          <TextField
            label={t("drop-us-a-line.contact-form.Subject line")}
            name="subject"
            value={form.subject}
            onChange={handleChange}
            required={true}
          />
        </Flex>
        <Flex flex={["", "6"]} flexDirection="column" mt={["16px", ""]}>
          <Box mb={["8px", "16px"]}>{t("drop-us-a-line.contact-form.Message")}</Box>
          <TextareaAutosize
            placeholder={t("drop-us-a-line.contact-form.message-textarea")}
            name="message"
            style={{
              height: "100%",
              maxWidth: "100%",
              fontFamily: "inherit",
              fontSize: "16px"
            }}
            rowsMin={4}
            value={form.message}
            onChange={handleChange}
            required={true}
          />
        </Flex>
        <Box flex={["", "1"]} ml={["", "32px"]} mt={["24px", "52px"]}>
          <Button variant="outlined" style={{ height: "100%", color: theme.colors.primary }} type="submit">
            {t("drop-us-a-line.contact-form.Send")}
          </Button>
        </Box>
      </Flex>
      {error && (
        <Box mt="16px">
          <Alert severity="error">There was an error processing your request. Please try again later.</Alert>
        </Box>
      )}
    </form>
  );
}

function DropUsALineEmailList(props) {
  const emailList = props.emailList;
  const anchorStyle = props.anchorStyle;

  return emailList.map((emailLine, index) => (
    <div key={index}>
      {emailLine.text}:{" "}
      <a href={`mailto:${emailLine.email}`} className={anchorStyle}>
        {emailLine.email}
      </a>
    </div>
  ));
}

export default () => {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const { t } = useTranslation("contact");

  const [mapIsMontrealLocation, setMapIsMontrealLocation] = useState(true);

  return (
    <>
      <Navbar />

      <Flex flexDirection="column" justifyContent="center" mb={["62px", "128px"]}>
        <Container>
          <p>
            {t("description.part1")}{" "}
            <a href="tel:+18665864477" className={classes.a}>
              {t("Phone").toLowerCase()}
            </a>{" "}
            {t("description.part2")}{" "}
            <a href="mailto:info@lumigroup.com" className={classes.a}>
              {t("Email").toLowerCase()}
            </a>
            {t("description.part3")}
          </p>
        </Container>

        <Box mt="96px">
          <H2 title={t("drop-us-a-line.title")} />
        </Box>
        <Container mt="32px">
          <EmailingForm />
          <Box mt="96px">
            <DropUsALineEmailList
              anchorStyle={clsx(classes.a, classes.noUnderline)}
              emailList={[
                { text: t("drop-us-a-line.any-information"), email: "info@lumigroup.com" },
                { text: t("drop-us-a-line.price-requests"), email: "soumission@lumigroup.com" },
                { text: t("drop-us-a-line.orders"), email: "commande@lumigroup.com" }
              ]}
            />
          </Box>
        </Container>

        <Box mt="96px">
          <H2 title={t("our-offices.title")} />
        </Box>
        <Container>
          <Flex flexDirection={["column", "row"]}>
            <Flex flex="1" flexDirection="column">
              <h3 style={{ color: theme.colors.primary }}>LumiGroup Montréal</h3>
              <div>
                5445, avenue de Gaspé, suite 201
                <br />
                Montréal, QC
                <br />
                Canada, H2T 3B2
                <br />
                {t("Email")}: info@lumigroup.com
                <br />
                {t("Telephone")}: 1.514.270.3552
                <br />
                {t("Toll free")}: 1.866.586.4477
                <br />
                <br />
                {t("our-offices.shipping-receiving")}
              </div>
            </Flex>

            <Flex flex="1" flexDirection="column">
              <h3 style={{ color: theme.colors.primary }}>LumiGroup Québec</h3>
              <div>
                1100, Saint-Vallier Est
                <br />
                Québec, QC
                <br />
                Canada, G1K 3R7
                <br />
                {t("Email")}: info@lumigroup.com
                <br />
                {t("Telephone")}: 1.418.262.0298
                <br />
                {t("Toll free")}: 1.866.586.4477
              </div>
            </Flex>
          </Flex>

          <Flex flexDirection="column">
            <Box
              sx={{
                mt: "24px",
                height: ["400px", "600px"]
              }}
            >
              <iframe
                title="map"
                src={
                  mapIsMontrealLocation
                    ? "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11180.660116609984!2d-73.5954958!3d45.5268845!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdcca61650c4603e1!2sLumiGroup!5e0!3m2!1sen!2sca!4v1606325826862!5m2!1sen!2sca"
                    : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2730.418344954135!2d-71.21470778439674!3d46.81576247914016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb895dfee06d9d9%3A0xf58d7c43bff1a026!2s1100%20Rue%20Saint-Vallier%20E%2C%20Qu%C3%A9bec%2C%20QC%20G1K%203R7!5e0!3m2!1sen!2sca!4v1607100401225!5m2!1sen!2sca"
                }
                frameBorder="0"
                style={{ border: 0, width: "100%", height: "100%" }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </Box>
            <Box
              mt="8px"
              alignSelf="flex-end"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                setMapIsMontrealLocation(!mapIsMontrealLocation);
              }}
            >
              {mapIsMontrealLocation ? "View Québec location" : "View Montreal location"}
            </Box>
          </Flex>
        </Container>

        <Box mt="96px">
          <H2 title={t("Our showroom")} />
        </Box>
        <Container>
          <Flex
            sx={{
              height: ["400px", "600px"]
            }}
            mt="64px"
          >
            <iframe
              title="showroom"
              src="https://www.google.com/maps/embed?pb=!4v1686496462157!6m8!1m7!1sCAoSLEFGMVFpcE9ObmxHcjhic09VZEZXM1I3RnFiaWN0UjVmNWs2dGZCSlhQQ3B0!2m2!1d45.5269669!2d-73.595631!3f93.41216735989397!4f-17.75455601709116!5f0.7820865974627469"
              frameBorder="0"
              style={{ border: 0, width: "100%", height: "100%" }}
              allowFullScreen
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </Flex>
        </Container>
      </Flex>
    </>
  );
};
