import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import { useTheme } from "emotion-theming";
import { H2 } from "../../components/h2";
import { HR } from "../../components/hr/hr";
import { api } from "../../api";
import { useLanguage } from "../../hooks/useLanguage";
import { Container } from "../../components/container";

export default ({ pageId }) => {
  const theme = useTheme();

  const language = useLanguage();

  const [page, setPage] = useState();

  useEffect(() => {
    api.get(`/${language}/textpages/${pageId}`).then(function (res) {
      setPage(res.data.textpage);
    });
  }, [language, pageId]);

  return (
    <>
      <Navbar />
      {page && <H2 title={page.title} rightSide={true} ml={["", "128px"]} />}
      {page && (
        <Container mt="92px">
          <div
            dangerouslySetInnerHTML={{ __html: page.content }}
            style={{ lineHeight: "24px", textAlign: "justify" }}
          />
        </Container>
      )}
      <HR color={theme.colors.secondary} mb={["64px", "128px"]} />
    </>
  );
};
