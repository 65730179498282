import React, { useEffect, useState, useContext } from "react";
import { Navbar } from "../../components/navbar";
import { api } from "../../api";
import { Box, Flex } from "reflexbox";
import { Link, useParams } from "react-router-dom";
import { H2 } from "../../components/h2";
import { ProductsGrid } from "../../components/products-grid/products-grid";
import { CatalogContext } from "../../contexts/catalog-context";
import ReactLoading from "react-loading";
import { useTheme } from "emotion-theming";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useLanguage } from "../../hooks/useLanguage";
import { useTranslation } from "react-i18next";
import { Container } from "../../components/container";
import { HoverItem } from "../../components/hover-item/hover-item";
import { ProjectContext } from "../../contexts/project-context";

const useStyles = makeStyles({
  tab: {
    color: "transparent",
    fontWeight: "bold",
    WebkitTextStroke: ({ theme }) => `1px ${theme.colors.secondary}`,
    "&:hover": {
      cursor: "pointer",
      color: ({ theme }) => theme.colors.primary,
      WebkitTextStroke: "unset"
    }
  },
  activeTab: {
    color: ({ theme }) => theme.colors.primary,
    WebkitTextStroke: "unset !important"
  }
});

function Projects({ projects }) {
  return (
    <Box mb={["64px", "128px"]} sx={{ display: "grid", gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr"], gap: "32px" }}>
      {projects.map((project, index) => (
        <Link key={index} to={`/projects/${project.id}`}>
          <HoverItem image={project.image.src} title={project.title} />
        </Link>
      ))}
    </Box>
  );
}

function Prodcuts({ application }) {
  const { catalog, loading } = useContext(CatalogContext);

  return loading ? (
    <Flex justifyContent="center">
      <ReactLoading type={"bubbles"} color={"#333"} height={667} width={375} />
    </Flex>
  ) : (
    <Box mt="32px" mb={["64px", "128px"]}>
      <ProductsGrid products={catalog.filter((x) => application.id in x.categories)} />
    </Box>
  );
}

export default () => {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const { applicationId } = useParams();

  const language = useLanguage();
  const { t } = useTranslation("common");

  const [application, setApplication] = useState();
  const [isProjectActive, setIsProjectActive] = useState(true);
  const [projects, setProjects] = useState([]);
  const projectsContext = useContext(ProjectContext).projects;

  useEffect(() => {
    api.get(`/${language}/categories/${applicationId}`).then((res) => {
      setApplication(res.data.category);
    });
  }, [applicationId, language]);

  useEffect(() => {
    if (!application || !projectsContext) {
      return;
    }

    api.get(`/us/blogs/tags`).then((res) => {
      const normalizedTitle = application.title
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(" ", "-")
        .toLowerCase();
      const tags = res.data.blogTags.filter(
        (x) =>
          x.title
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(" ", "-")
            .toLowerCase() === normalizedTitle
      );
      if (tags.length === 0) {
        return;
      }
      const tagCalls = [];
      for (const tag of tags) {
        tagCalls.push(api.get(`/us/blogs/articles/tags?tag=${tag.id}&limit=250`));
      }
      Promise.all(tagCalls).then((ires) => {
        const blogArticleTags = ires.map((x) => x.data.blogArticleTags).flat();
        const p = [];
        const locale = language === "en" ? "en_US" : "fr_CA";
        for (const articleTag of blogArticleTags) {
          for (const project of projectsContext) {
            if (project.id === articleTag.article.resource.id) {
              p.push(project[locale]);
            }
          }
        }
        setProjects(p);
      });
    });
  }, [application, projectsContext]);

  if (!application) {
    return null;
  }

  return (
    <>
      <Navbar color="black" />
      <H2 title={application.title} rightSide={true} />
      <Container mt={["32px", "64px"]}>
        <div dangerouslySetInnerHTML={{ __html: application.content }} />
      </Container>
      <Box sx={{ maxHeight: "80vh", width: "100%" }} mt={["64px", "128px"]}>
        <img
          src={application.image.src}
          width="100%"
          style={{ maxHeight: "80vh" }}
          alt={application.title}
          title={application.title}
        />
      </Box>
      <Container>
        <Flex mt={["64px", "128px"]} justifyContent="center" mb="128px">
          <Box
            className={clsx(classes.tab, isProjectActive && classes.activeTab)}
            sx={{ fontSize: ["24pt", "40pt"] }}
            onClick={() => {
              setIsProjectActive(true);
            }}
            mr={["40px", "64px"]}
          >
            {t("Projects")}
          </Box>
          <Box
            className={clsx(classes.tab, !isProjectActive && classes.activeTab)}
            sx={{ fontSize: ["24pt", "40pt"] }}
            onClick={() => {
              setIsProjectActive(false);
            }}
          >
            {t("Products")}
          </Box>
        </Flex>
        {isProjectActive ? <Projects projects={projects} /> : <Prodcuts application={application} />}
      </Container>
    </>
  );
};
