import React, { useCallback, useEffect, useRef, useState } from "react";
import { H2 } from "../../components/h2";
import { useTheme } from "emotion-theming";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Flex } from "reflexbox";
import { Navbar } from "../../components/navbar";
import { api } from "../../api";
import { UnloadedProduct } from "../../components/unloaded-product";
import { Link } from "react-router-dom";
import { ExpandMore } from "@material-ui/icons";
import { useLanguage } from "../../hooks/useLanguage";
import { useTranslation } from "react-i18next";
import { Container } from "../../components/container";

function DropdownNav(props) {
  const theme = useTheme();

  const { t } = useTranslation("common");

  const { title, color, options, parentCategory, linkTo } = props;

  const node = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleOutsideClick = useCallback((event) => {
    if (node.current.contains(event.target)) {
      return;
    }

    setIsOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <Flex flexDirection="column" ref={node}>
      <Flex style={{ cursor: "pointer", border: "1px solid #707070", userSelect: "none" }} onClick={handleClick}>
        <Box
          flex="1"
          color={color}
          sx={{
            fontWeight: "bold",
            fontSize: "14pt",
            padding: ["8px 32px", "8px 64px"]
          }}
        >
          {title}
        </Box>
        <Flex alignItems="center" pr="8px">
          <ExpandMore style={{ color: "#555" }} />
        </Flex>
      </Flex>
      {isOpen && (
        <Flex style={{ position: "relative" }}>
          <Flex
            flexDirection="column"
            style={{
              border: "1px solid #707070",
              borderTop: "0px",
              position: "absolute",
              zIndex: 1000,
              backgroundColor: "white",
              width: "100%"
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                color: color,
                cursor: "pointer",
                "&:hover": { backgroundColor: "#000", opacity: "0.1" }
              }}
              py="4px"
            >
              <Link
                to={{ pathname: `products/${linkTo}` }}
                style={{ display: "block", color: theme.colors.primary, textDecoration: "none" }}
              >
                {t("All products")}
              </Link>
            </Box>
            {options?.map((option, index) => (
              <Box
                sx={{
                  textAlign: "center",
                  color: color,
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#000", opacity: "0.1" }
                }}
                key={index}
                py="4px"
              >
                <Link
                  to={{ pathname: `products/${parentCategory}`, state: { category: option } }}
                  style={{ display: "block", color: theme.colors.primary, textDecoration: "none" }}
                >
                  {option.title}
                </Link>
              </Box>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default () => {
  const theme = useTheme();

  const useStyles = makeStyles({
    button: {
      color: theme.colors.primary,
      fontWeight: "bold",
      fontSize: "14pt",
      cursor: "pointer",
      border: "1px solid #707070",
      backgroundColor: "transparent",
      padding: "8px 64px"
    }
  });

  const classes = useStyles();

  const language = useLanguage();
  const { t } = useTranslation(["common", "products"]);

  const [allCategories, setAllCategories] = useState([]);

  const [quickShipTop3, setQuickShipTop3] = useState([]);
  const [lightingTop3, setLightingTop3] = useState([]);
  const [furnitureTop3, setFurnitureTop3] = useState([]);
  const [acousticTop3, setAcousticTop3] = useState([]);
  const [lightingCategories, setLightingCategories] = useState([]);
  const [furnitureCategories, setFurnitureCategories] = useState([]);

  useEffect(() => {
    api.get(`/${language}/categories/products`, { params: { category: "1457018", limit: 3 } }).then((res) => {
      setQuickShipTop3(res.data.categoriesProducts);
    });

    api.get(`/${language}/categories/products`, { params: { category: "847023", limit: 3 } }).then((res) => {
      setLightingTop3(res.data.categoriesProducts);
    });

    api.get(`/${language}/categories`).then((res) => {
      setAllCategories(res.data);
      setLightingCategories(res.data.filter((x) => x.parent?.resource?.id === 847023));
      setFurnitureCategories(res.data.filter((x) => x.parent?.resource?.id === 847028));
    });

    api.get(`/${language}/categories/products`, { params: { category: "847028", limit: 3 } }).then((res) => {
      setFurnitureTop3(res.data.categoriesProducts);
    });

    api.get(`/${language}/categories/products`, { params: { category: "1190724", limit: 3 } }).then((res) => {
      setAcousticTop3(res.data.categoriesProducts);
    });
  }, [language]);

  return (
    <>
      <Navbar />
      <Flex flexDirection="column">
        <Flex flexDirection="column">
          <H2 title={t("common:Quick ship")} />
          <Container>
            <Flex mt="48px" justifyContent="center">
              <Link to="products/quick-ship">
                <button className={classes.button}>{t("products:quick-ship.button")}</button>
              </Link>
            </Flex>
            <Flex justifyContent="center">
              <Box
                sx={{
                  display: "grid",
                  gap: "24px",
                  gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr"],
                  maxWidth: "800px"
                }}
                alignSelf="center"
                mt="48px"
              >
                {quickShipTop3.map((category, index) => (
                  <UnloadedProduct key={index} unloadedProduct={category.product} />
                ))}
              </Box>
            </Flex>
          </Container>
        </Flex>

        <Flex flexDirection="column" mt="128px">
          <H2 title={t("common:Lighting")} />
          <Container>
            <Flex mt="48px" justifyContent="center">
              <DropdownNav
                title={t("products:lighting.select")}
                color={theme.colors.primary}
                options={lightingCategories}
                parentCategory="lighting"
                linkTo="lighting"
              />
            </Flex>
            <Flex justifyContent="center">
              <Box
                sx={{
                  display: "grid",
                  gap: "24px",
                  gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr"],
                  maxWidth: "800px"
                }}
                alignSelf="center"
                mt="48px"
              >
                {lightingTop3.map((category, index) => (
                  <UnloadedProduct key={index} unloadedProduct={category.product} />
                ))}
              </Box>
            </Flex>
          </Container>
        </Flex>

        <Flex flexDirection="column" my="128px">
          <H2 title={t("common:Furniture")} />
          <Container>
            <Flex mt="48px" justifyContent="center">
              <DropdownNav
                title={t("products:furniture.select")}
                color={theme.colors.primary}
                options={furnitureCategories}
                parentCategory="furniture"
                linkTo="furniture"
              />
            </Flex>
            <Flex justifyContent="center">
              <Box
                sx={{
                  display: "grid",
                  gap: "24px",
                  gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr"],
                  maxWidth: "800px"
                }}
                alignSelf="center"
                mt="48px"
              >
                {furnitureTop3.map((category, index) => (
                  <UnloadedProduct key={index} unloadedProduct={category.product} />
                ))}
              </Box>
            </Flex>
          </Container>
        </Flex>

        <Flex flexDirection="column">
          <H2 title={t("common:Acoustic")} />
          <Container>
            <Flex mt="48px" justifyContent="center">
              <Link to="products/acoustic">
                <button className={classes.button}>{t("products:acoustic.button")}</button>
              </Link>
            </Flex>
            <Flex justifyContent="center">
              <Box
                sx={{
                  display: "grid",
                  gap: "24px",
                  gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr"],
                  maxWidth: "800px"
                }}
                alignSelf="center"
                mt="48px"
              >
                {acousticTop3.map((category, index) => (
                  <UnloadedProduct key={index} unloadedProduct={category.product} />
                ))}
              </Box>
            </Flex>
          </Container>
        </Flex>
      </Flex>
    </>
  );
};
