import React, { useEffect, useState } from "react";
import { Box, Flex } from "reflexbox";
import { makeStyles } from "@material-ui/core";
import { H2 } from "../../components/h2";
import { useTheme } from "emotion-theming";
import { ArrowBack, ArrowForward, DoubleArrow } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { api } from "../../api";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLanguage } from "../../hooks/useLanguage";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import { Carousel } from "react-responsive-carousel";

const useStyles = makeStyles({
  list: {
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  arrow: {
    border: ({ theme }) => `1px solid ${theme.colors.secondary}`,
    cursor: "pointer",
    color: ({ theme }) => theme.colors.secondary
  }
});

export function Projects() {
  const language = useLanguage();
  const { t } = useTranslation("common");

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    api.get(`/${language}/textpages/964541`).then(function (res) {
      const re = new RegExp("http.*?admin\\/articles\\/([0-9]*)", "gm");
      const textpage = res.data.textpage;
      const projectsIdArray = [...textpage.content.matchAll(re)].map((match) => match[1]);
      const apiCalls = projectsIdArray.map((projectId) => api.get(`/${language}/blogs/articles/${projectId}`));
      Promise.all(apiCalls).then((response) => {
        setProjects(response.map((r) => r?.data?.blogArticle));
      });
    });
  }, [language]);

  if (projects.length === 0) {
    return null;
  }

  return (
    <>
      <Box overflowX="hidden">
        <ScrollAnimation animateIn="slideInRight">
          <H2 title={t("Projects")} overflowX="initial" />
        </ScrollAnimation>
      </Box>
      <Box display={["none", "initial"]}>
        <ProjectListNew projects={projects} />
      </Box>
      <Box display={["initial", "none"]}>
        <ProjectListMobile projects={projects} />
      </Box>
    </>
  );
}

function ProjectListMobile({ projects }) {
  const theme = useTheme();

  const classes = useStyles({ theme });

  return (
    <Box mt="64px" mx="8px">
      <Carousel
        infiniteLoop={true}
        showStatus={false}
        renderThumbs={() => null}
        renderIndicator={() => null}
        renderArrowPrev={(clickHandler, hasNext, label) => (
          <Flex
            flexDirection="column"
            justifyContent="center"
            sx={{ position: "absolute", left: 0, top: 0, bottom: 0, zIndex: 2 }}
          >
            <Flex onClick={clickHandler} alignItems="center" className={classes.arrow} p={["6px", "12px"]}>
              <ArrowBack />
            </Flex>
          </Flex>
        )}
        renderArrowNext={(clickHandler, hasNext, label) => (
          <Flex
            flexDirection="column"
            justifyContent="center"
            sx={{ position: "absolute", right: 0, top: 0, bottom: 0, zIndex: 2 }}
          >
            <Flex onClick={clickHandler} alignItems="center" className={classes.arrow} p={["6px", "12px"]}>
              <ArrowForward />
            </Flex>
          </Flex>
        )}
      >
        {projects.map((project, index) => (
          <Box key={index} mx="48px" height="256px">
            <Link to={`/projects/${project.id}`} style={{ textDecoration: "none", color: "black" }}>
              <Flex flexDirection="column" height="100%">
                <Box flex="1" overflow="hidden">
                  <img
                    src={project.image.src}
                    width="100%"
                    height="100%"
                    style={{ objectFit: "fill" }}
                    alt={project.title}
                    title={project.title}
                  />
                </Box>
                <Flex>
                  <Box sx={{ fontWeight: "bold", fontSize: ["8pt", "16pt"] }} pl={["8px", "16px"]}>
                    {project.title}
                  </Box>
                  <Flex flex="1" alignItems="center" px={["16px", "48px"]}>
                    <div style={{ width: "100%", borderTop: `1px solid ${theme.colors.primary}` }}></div>
                  </Flex>
                </Flex>
              </Flex>
            </Link>
          </Box>
        ))}
        <MoreProjects />
      </Carousel>
    </Box>
  );
}

function MoreProjects() {
  const theme = useTheme();

  const { t } = useTranslation("home");

  return (
    <Flex alignItems="center" justifyContent="center" height="100%">
      <Link to="/projects" style={{ textDecoration: "none" }}>
        <DoubleArrow style={{ fontSize: "128pt", color: theme.colors.secondary }} />
        <div style={{ fontWeight: "bold", fontSize: "16pt", color: theme.colors.secondary, textAlign: "center" }}>
          {t("More Projects")}
        </div>
      </Link>
    </Flex>
  );
}

function ProjectListNew({ projects }) {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const [projectPair, setProjectPair] = useState([]);

  useEffect(() => {
    const newProjects = [...projects];
    newProjects.push("more");
    setProjectPair(generateProjectPair(newProjects));
  }, [projects]);

  return (
    <Box mt="64px" mx="8px">
      <Carousel
        infiniteLoop={true}
        showStatus={false}
        renderThumbs={() => null}
        renderIndicator={() => null}
        renderArrowPrev={(clickHandler, hasNext, label) => (
          <Flex
            flexDirection="column"
            justifyContent="center"
            sx={{ position: "absolute", left: 0, top: 0, bottom: 0, zIndex: 2 }}
          >
            <Flex onClick={clickHandler} alignItems="center" className={classes.arrow} p={["6px", "12px"]}>
              <ArrowBack />
            </Flex>
          </Flex>
        )}
        renderArrowNext={(clickHandler, hasNext, label) => (
          <Flex
            flexDirection="column"
            justifyContent="center"
            sx={{ position: "absolute", right: 0, top: 0, bottom: 0, zIndex: 2 }}
          >
            <Flex onClick={clickHandler} alignItems="center" className={classes.arrow} p={["6px", "12px"]}>
              <ArrowForward />
            </Flex>
          </Flex>
        )}
      >
        {projectPair.map((pair, i) => (
          <Flex key={i} mx="64px" style={{ gap: "96px", height: "512px" }}>
            {pair.map((project, ii) =>
              project !== "more" ? (
                <Box key={ii} flex="1">
                  <Link to={`/projects/${project.id}`} style={{ textDecoration: "none", color: "black" }}>
                    <Flex flexDirection="column" height="100%">
                      <Box flex="1" overflow="hidden">
                        <img
                          src={project.image.src}
                          width="100%"
                          height="100%"
                          style={{ objectFit: "fill" }}
                          alt={project.title}
                          title={project.title}
                        />
                      </Box>
                      <Flex>
                        <Box sx={{ fontWeight: "bold", fontSize: ["8pt", "16pt"] }} pl={["8px", "16px"]}>
                          {project.title}
                        </Box>
                        <Flex flex="1" alignItems="center" px={["16px", "48px"]}>
                          <div style={{ width: "100%", borderTop: `1px solid ${theme.colors.primary}` }} />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Link>
                </Box>
              ) : (
                <Box key={ii} flex="1">
                  <MoreProjects />
                </Box>
              )
            )}
          </Flex>
        ))}
      </Carousel>
    </Box>
  );
}

function generateProjectPair(projects) {
  return projects.reduce(function (result, value, index, array) {
    if (index % 2 === 0) result.push(array.slice(index, index + 2));
    return result;
  }, []);
}
