import React, { useEffect, useState } from "react";
import { Box, Flex } from "reflexbox";
import { H2 } from "../../components/h2";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "emotion-theming";
import { useSortedBrands } from "../../hooks/useSortedBrands";

const useStyles = makeStyles({
  arrow: {
    border: ({ theme }) => `1px solid ${theme.colors.secondary}`,
    cursor: "pointer",
    color: ({ theme }) => theme.colors.secondary
  }
});

export function Manufacturers() {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const { t } = useTranslation(["common", "home"]);

  const sortedBrands = useSortedBrands();
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const brandsArray = [];
    for (let i = 0; i < sortedBrands.length; i += 9) {
      const chunk = sortedBrands.slice(i, i + 9);
      brandsArray.push(chunk);
    }
    setBrands(brandsArray);
  }, [sortedBrands]);

  return (
    <>
      <Box overflowX="hidden">
        <ScrollAnimation animateIn="slideInLeft">
          <H2 title={t("home:manufacturers.header")} rightSide={true} overflowX="initial" />
        </ScrollAnimation>
      </Box>
      <Box mx={["8px", "64px"]} mt="64px">
        <Carousel
          infiniteLoop={true}
          showStatus={false}
          renderThumbs={() => null}
          renderIndicator={() => null}
          renderArrowPrev={(clickHandler, hasNext, label) => (
            <Flex
              flexDirection="column"
              justifyContent="center"
              sx={{ position: "absolute", left: 0, top: 0, bottom: 0, zIndex: 2 }}
            >
              <Flex onClick={clickHandler} alignItems="center" className={classes.arrow} p={["6px", "12px"]}>
                <ArrowBack />
              </Flex>
            </Flex>
          )}
          renderArrowNext={(clickHandler, hasNext, label) => (
            <Flex
              flexDirection="column"
              justifyContent="center"
              sx={{ position: "absolute", right: 0, top: 0, bottom: 0, zIndex: 2 }}
            >
              <Flex onClick={clickHandler} alignItems="center" className={classes.arrow} p={["6px", "12px"]}>
                <ArrowForward />
              </Flex>
            </Flex>
          )}
        >
          {brands.map((brandArray, i) => (
            <Box key={i} sx={{ display: "grid", gridTemplate: "1fr 1fr 1fr / 1fr 1fr 1fr", gap: "16px" }} mx="48px">
              {brandArray.map((brand, ii) => (
                <ScrollAnimation key={ii} animateIn="bounceIn">
                  <Flex justifyContent="center">
                    <Link to={`manufacturers/${brand.id}`}>
                      <Box sx={{ width: ["64px", "176px"], height: ["64px", "176px"] }}>
                        <img
                          src={brand.image.src}
                          style={{ width: "100%", height: "100%" }}
                          alt={brand.title}
                          title={brand.title}
                        />
                      </Box>
                    </Link>
                  </Flex>
                </ScrollAnimation>
              ))}
            </Box>
          ))}
        </Carousel>
      </Box>
    </>
  );
}
