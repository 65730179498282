import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import { useLanguage } from "../../hooks/useLanguage";
import { api } from "../../api";
import { useParams } from "react-router-dom";
import { Box, Flex } from "reflexbox";
import { H2 } from "../../components/h2";
import { useTranslation } from "react-i18next";
import { Container } from "../../components/container";
import { useTheme } from "emotion-theming";
import { HR } from "../../components/hr";

function BlogTitleAndAuthor({ career }) {
  const { t } = useTranslation("common");

  return (
    <>
      {career && (
        <>
          <Box style={{ fontWeight: "bold", fontSize: "16pt" }}>{career.title}</Box>
          <Box color="grey" mt="8px">
            {t("common:By")} {career.author}
          </Box>
        </>
      )}
    </>
  );
}

export default () => {
  const { careerId } = useParams();

  const theme = useTheme();

  const language = useLanguage();
  const { t } = useTranslation("common");

  const [career, setCareer] = useState();

  useEffect(() => {
    api.get(`/${language}/blogs/articles/${careerId}`).then((res) => {
      const result = res.data.blogArticle;
      setCareer(result);
    });
  }, [language, careerId]);

  return (
    <>
      <Navbar />
      <H2 title={t("common:Careers")} rightSide={true} mb={["32px", "72px"]} />
      {career && (
        <Container mb={["32px", "96px"]}>
          <Flex justifyContent={["", "space-between"]} flexDirection={["column", "row"]} mb={["", "32px"]}>
            <Box>
              <BlogTitleAndAuthor career={career} />
            </Box>
          </Flex>
          <Box overflowX={["hidden", ""]}>
            <div dangerouslySetInnerHTML={{ __html: career.content }}></div>
          </Box>
        </Container>
      )}
      <Box mb="160px" mt="64px">
        <HR color={theme.colors.primary} />
      </Box>
    </>
  );
};
