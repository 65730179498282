import { useTheme } from "emotion-theming";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useLocation } from "react-router-dom";
import { Flex } from "reflexbox";
import { Container } from "../../components/container";
import { H2 } from "../../components/h2";
import { HR } from "../../components/hr/hr";
import { Navbar } from "../../components/navbar";
import { ProductsGrid } from "../../components/products-grid/products-grid";
import { CatalogContext } from "../../contexts/catalog-context";

export default () => {
  const location = useLocation();

  const theme = useTheme();

  const { t } = useTranslation("common");

  const { catalog, loading } = useContext(CatalogContext);

  return (
    <>
      <Navbar />
      <H2 title={t("Acoustic")} rightSide={true} ml={["", "156px"]} />
      {loading ? (
        <Flex justifyContent="center">
          <ReactLoading type={"bubbles"} color={"#333"} height={667} width={375} />
        </Flex>
      ) : (
        <Container mt="32px" mb="128px">
          <ProductsGrid products={catalog.filter((x) => 1190724 in x.categories)} />
        </Container>
      )}
      <HR display={["block", "none"]} color={theme.colors.secondary} mb="64px" />
    </>
  );
};
