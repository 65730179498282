import React from "react";
import { Box, Flex } from "reflexbox";
import { makeStyles } from "@material-ui/core";
import { H2 } from "../../components/h2";
import { Navbar } from "../../components/navbar";
import { useTheme } from "emotion-theming";
import { ArrowForward } from "@material-ui/icons";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import { Manufacturers } from "./manufacturers";
import { Projects } from "./projects";
import { Container } from "../../components/container";

const useStyles = makeStyles({
  highlight: {
    fontWeight: "bold",
    color: ({ theme }) => theme.colors.primary
  },
  underline: {
    textDecoration: ({ theme }) => `underline ${theme.colors.primary}`
  },
  link: {
    textDecoration: "none",
    color: "black"
  }
});

export default () => {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const { t } = useTranslation(["common", "home"]);

  return (
    <>
      <Box
        style={{ position: "absolute", left: 0, right: 0, zIndex: 100, backgroundColor: "rgba(255, 255, 255, 0.4)" }}
      >
        <Box>
          <Navbar color="black" />
        </Box>
      </Box>
      <Box style={{ height: "100vh", zIndex: -1 }}>
        {/* {video ? ( */}
        <video style={{ width: "100%", height: "100%", objectFit: "cover" }} autoPlay muted loop>
          {/* <source src={video} /> */}
          <source src="/assets/homepage-video.mp4" />
        </video>
      </Box>
      <Box mt="64px">
        <Box overflowX="hidden">
          <ScrollAnimation animateIn="slideInRight">
            <H2 title={t("home:lets-talk-about-us.header")} overflowX="initial" />
          </ScrollAnimation>
        </Box>
        <Container mt="64px">
          <Flex justifyContent="center">
            <Box>
              <Box>
                <span className={classes.highlight}>LumiGroup</span> {t("home:lets-talk-about-us.description.part1")}
              </Box>
              <Box mt="24px">
                {t("home:lets-talk-about-us.description.part2.beforeUnderline")}{" "}
                <span className={classes.underline}>{t("home:lets-talk-about-us.description.part2.underline")}</span>{" "}
                {t("home:lets-talk-about-us.description.part2.afterUnderline")}
              </Box>
              <Box mt="48px">
                <Link to="about" className={classes.link}>
                  <Flex>
                    <ArrowForward /> {t("home:lets-talk-about-us.description.Learn More")}
                  </Flex>
                </Link>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box mt="128px">
        <Manufacturers />
      </Box>
      <Box mt="128px" mb={["48px", "160px"]}>
        <Projects />
      </Box>
    </>
  );
};
