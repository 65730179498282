import React from "react";
import { Flex, Box } from "reflexbox";
import { useTheme } from "emotion-theming";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Newsletter } from "../../components/newsletter";
import { useTranslation } from "react-i18next";
import { PerfectImageRatio } from "../perfect-image-ratio/perfect-image-ratio";

const useStyles = makeStyles({
  title: {
    color: ({ theme }) => theme.colors.primary,
    fontWeight: "bold",
    fontSize: "16pt",
    textAlign: "center"
  },
  button: {
    color: ({ theme }) => theme.colors.primary,
    fontWeight: "bold",
    fontSize: "14pt",
    cursor: "pointer",
    border: "1px solid white",
    backgroundColor: "transparent",
    padding: "8px 64px"
  }
});

export function LetsTalk() {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const { t } = useTranslation("lets-talk");

  return (
    <>
      <Box style={{ position: "relative" }} mt={["64px", "128px"]}>
        <PerfectImageRatio src="/assets/lets-talk-project.jpg" />
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "black",
            opacity: 0.4
          }}
        />
        <Box style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
          <Flex flexDirection="column" justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <Box className={classes.title} mt="80px">
              {t("need-help")}
            </Box>
            <Box mt="56px">
              <Link to="/contact">
                <button className={classes.button}>{t("lets-talk-button")}</button>
              </Link>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Newsletter />
    </>
  );
}
