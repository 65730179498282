import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Navbar } from "../../components/navbar";
import { useTheme } from "emotion-theming";
import { ProductsGrid } from "../../components/products-grid/products-grid";
import { CatalogContext } from "../../contexts/catalog-context";
import { H2 } from "../../components/h2";
import { Flex } from "reflexbox";
import ReactLoading from "react-loading";
import { HR } from "../../components/hr/hr";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hooks/useLanguage";
import { api } from "../../api";
import { Container } from "../../components/container";

export default () => {
  const location = useLocation();
  const defaultSubCategory = location.state?.category;

  const theme = useTheme();

  const language = useLanguage();
  const { t } = useTranslation("common");

  const { catalog, loading } = useContext(CatalogContext);

  const [lightingCategories, setLightingCategories] = useState([]);

  useEffect(() => {
    api.get(`/${language}/categories`, { params: { parent: "847023" } }).then((res) => {
      setLightingCategories(res.data);
    });
  }, [language]);

  return (
    <>
      <Navbar />
      <H2 title={t("Lighting")} rightSide={true} ml={["", "156px"]} />
      {loading ? (
        <Flex justifyContent="center">
          <ReactLoading type={"bubbles"} color={"#333"} height={667} width={375} />
        </Flex>
      ) : (
        <Container mt="32px" mb={["128px"]}>
          <ProductsGrid
            products={catalog.filter((x) => 847023 in x.categories)}
            subCategories={lightingCategories}
            defaultSubCategory={defaultSubCategory}
          />
        </Container>
      )}
      <HR display={["block", "none"]} color={theme.colors.secondary} mb="64px" />
    </>
  );
};
