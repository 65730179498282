import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "emotion-theming";
import { createMuiTheme, ThemeProvider as MaterialThemeProvider } from "@material-ui/core/styles";
import { Footer } from "./components/footer";
import { LetsTalk } from "./components/lets-talk";
import { ScrollToTop } from "./components/scroll-to-top";
import { CatalogContextProvider } from "./contexts/catalog-context";

import Home from "./screens/home";
import Manufacturers from "./screens/manufacturers";
import ManufacturersDetail from "./screens/manufacturers-detail";
import Projects from "./screens/projects";
import About from "./screens/about";
import Contact from "./screens/contact";
import Products from "./screens/products";
import ProductsDetail from "./screens/products-detail";
import QuickShip from "./screens/quick-ship";
import Applications from "./screens/applications";
import ApplicationsDetail from "./screens/applications-detail";
import Lighting from "./screens/lighting";
import Furniture from "./screens/furniture";
import Acoustic from "./screens/acoustic";
import Blogs from "./screens/blogs";
import BlogsDetail from "./screens/blogs-detail";
import Careers from "./screens/careers";
import CareersDetail from "./screens/careers-detail";
import DynamicTextPage from "./screens/dynamic-text-page";
import { ProjectContextProvider } from "./contexts/project-context";
import { EmailPopup } from "./components/email-popup";

const theme = {
  breakpoints: ["1280px"],
  colors: {
    primary: "#E5AA3A",
    secondary: "#707070"
  }
};

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.colors.primary
    },
    secondary: {
      main: theme.colors.secondary
    }
  }
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <MaterialThemeProvider theme={materialTheme}>
          <BrowserRouter>
            <ScrollToTop>
              <Suspense fallback={<div>Loading...</div>}>
                <CatalogContextProvider>
                  <ProjectContextProvider>
                    <Switch>
                      <Route path="/" component={Home} exact />
                      <Route path="/fc">
                        <Redirect to="/" />
                      </Route>
                      <Route path="/us">
                        <Redirect to="/" />
                      </Route>
                      <Route path="/manufacturers" component={Manufacturers} exact />
                      <Route path="/manufacturers/:brandId" component={ManufacturersDetail} />
                      <Route path="/projects" component={Projects} />
                      <Route path="/about" component={About} exact />
                      <Route path="/contact" component={Contact} />
                      <Route path="/products" component={Products} exact />
                      <Route path="/products/quick-ship" component={QuickShip} exact />
                      <Route path="/products/lighting" component={Lighting} />
                      <Route path="/products/furniture" component={Furniture} />
                      <Route path="/products/acoustic" component={Acoustic} />
                      <Route path="/products/:productId" component={ProductsDetail} />
                      <Route path="/applications" component={Applications} exact />
                      <Route path="/applications/:applicationId" component={ApplicationsDetail} />
                      <Route path="/about/blogs" component={Blogs} exact />
                      <Route path="/about/blogs/:blogId" component={BlogsDetail} />
                      <Route path="/careers" component={Careers} exact />
                      <Route path="/careers/:careerId" component={CareersDetail} />

                      <Route
                        path="/shipping-and-returns"
                        render={(props) => <DynamicTextPage {...props} pageId={433861} />}
                      />
                      <Route
                        path="/payment-methods"
                        render={(props) => <DynamicTextPage {...props} pageId={433860} />}
                      />
                      <Route
                        path="/terms-and-conditions"
                        render={(props) => <DynamicTextPage {...props} pageId={433857} />}
                      />
                      <Route path="/disclaimer" render={(props) => <DynamicTextPage {...props} pageId={433858} />} />
                      <Route
                        path="/privacy-policy"
                        render={(props) => <DynamicTextPage {...props} pageId={433859} />}
                      />
                    </Switch>
                    <LetsTalk />
                    <Footer />
                    <EmailPopup />
                  </ProjectContextProvider>
                </CatalogContextProvider>
              </Suspense>
            </ScrollToTop>
          </BrowserRouter>
        </MaterialThemeProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
