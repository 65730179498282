import React from "react";
import { Link } from "react-router-dom";
import { Flex, Box } from "reflexbox";
import { makeStyles } from "@material-ui/core";
import Socials from "../social-buttons";
import { SiteMap } from "./sitemap";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  link: {
    textDecorationLine: "none",
    color: "white",
    "&:hover": {
      textDecorationLine: "underline !important"
    }
  }
});

export function Footer() {
  const classes = useStyles();

  const { t } = useTranslation("footer");

  return (
    <>
      <Box
        mt="128px"
        display="grid"
        sx={{
          gridTemplate: ["1fr 4fr 1fr 1fr / 1fr 4fr 2fr", "3fr 4fr 1fr 1fr / 1fr 4fr 2fr"],
          backgroundColor: "#E5A836",
          padding: "32px"
        }}
      >
        <Flex sx={{ gridRowEnd: ["", "span 4"], gridColumnEnd: ["span 3", "span 1"] }}>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "24pt" }}>LumiGroup</div>
        </Flex>
        <Box
          sx={{
            gridRowEnd: ["", "span 4"],
            gridColumnEnd: ["span 2", "span 1"],
            display: "grid",
            gridTemplate: ["1fr 1fr / 1fr 1fr", "1fr / 1fr 1fr 1fr 1fr"],
            gap: "24px 0"
          }}
        >
          <SiteMap />
        </Box>
        <Flex sx={{ display: ["", "block"] }} justifyContent={["flex-end", ""]}>
          <Flex flexDirection={["column", "row"]} alignItems="center" justifyContent="space-evenly">
            <Socials color="white" />
          </Flex>
        </Flex>
        <Box sx={{ gridColumn: ["span 2", "3 / span 1"], lineHeight: "32px" }}>
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Link to="/terms-and-conditions" className={classes.link}>
              {t("Terms and Conditions")}
            </Link>
            <Link to="/disclaimer" className={classes.link}>
              {t("Disclaimer")}
            </Link>
            <Link to="/privacy-policy" className={classes.link}>
              {t("Privacy Policy")}
            </Link>
          </Flex>
        </Box>
        <Box sx={{ gridColumn: ["span 3", "3 / span 1"], gridRowStart: ["", "4"] }}>
          <span style={{ color: "white", fontSize: "8pt" }}>
            &copy; {new Date().getFullYear()} LumiGroup - {t("All rights reserved")}
          </span>
        </Box>
      </Box>
    </>
  );
}
