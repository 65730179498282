import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import { useLanguage } from "../../hooks/useLanguage";
import { api } from "../../api";
import { Box, Flex } from "reflexbox";
import { Link } from "react-router-dom";
import { H2 } from "../../components/h2";
import { HR } from "../../components/hr";
import { useTheme } from "emotion-theming";
import { ArrowForward } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Container } from "../../components/container";

const useStyles = makeStyles({
  link: {
    textDecoration: "inherit",
    color: "inherit",
    "&:hover": {
      color: ({ theme }) => theme.colors.primary,
      fontWeight: "bold"
    }
  }
});

function Career({ career }) {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const { t } = useTranslation("careers");

  const [hover, setHover] = useState(false);

  return (
    <Link
      to={`/careers/${career.id}`}
      className={classes.link}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <Flex mt="32px" sx={{ height: "30px" }}>
        <Flex flex="4" alignItems="center">
          <Box>{career.title}</Box>
        </Flex>
        <Flex flex="1" alignItems="center">
          <Box mr="24px">{t("Details")}</Box>
          {hover && (
            <Box
              sx={{ color: theme.colors.primary, border: `1px solid ${theme.colors.primary}`, fontSize: "2px" }}
              px="12px"
            >
              <ArrowForward />
            </Box>
          )}
        </Flex>
      </Flex>
    </Link>
  );
}

export default () => {
  const theme = useTheme();

  const language = useLanguage();
  const { t } = useTranslation("careers");

  const [careers, setCareers] = useState([]);

  useEffect(() => {
    api.get(`/${language}/blogs/articles?blog_id=1436&limit=250`).then((res) => {
      const locale = language === "en" ? "en_US" : "fr_CA";
      const results = res.data.blogArticles.filter(
        (x) => x.blog.resource.id === 1436 && x.language.locale === locale && x.isPublished
      );
      setCareers(results);
    });
  }, [language]);

  return (
    <>
      <Navbar />
      <H2 title={t("common:Careers")} rightSide={true} mb={["32px", "72px"]} />
      <Container>
        <Flex sx={{ fontWeight: "bold", fontSize: "14pt" }}>
          <Box flex="4">{t("careers:Current Offers")}</Box>
          <Box flex="1">{t("careers:More")}</Box>
        </Flex>
        <hr />
        {careers.map((career, index) => (
          <Career key={index} career={career} />
        ))}
      </Container>
      <Box mb="160px" mt="64px">
        <HR color={theme.colors.primary} />
      </Box>
    </>
  );
};
