import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import { useLanguage } from "../../hooks/useLanguage";
import { api } from "../../api";
import { Box, Flex } from "reflexbox";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { H2 } from "../../components/h2";
import { useTranslation } from "react-i18next";
import { Container } from "../../components/container";

const useStyles = makeStyles({
  hoverContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(51,51,51,0.8)",
    transition: "0.5s ease"
  }
});

function BlogTitleAndAuthor({ blog }) {
  const { t } = useTranslation("common");

  return (
    <>
      {blog && (
        <>
          <Box style={{ fontWeight: "bold", fontSize: "16pt" }} mt="16px">
            {blog.title}
          </Box>
          <Box color="grey">
            {t("By")} {blog.author}
          </Box>
        </>
      )}
    </>
  );
}

function Blog({ blog, locale }) {
  const classes = useStyles();

  const { t } = useTranslation("common");

  const [hover, setHover] = useState(false);

  return (
    <Box mb={["32px", "72px"]}>
      <Box style={{ position: "relative" }}>
        <Link to={`/about/blogs/${blog.id}`} style={{ textDecoration: "none" }}>
          <Box sx={{ height: ["200px", "600px"], overflowY: "hidden" }}>
            <img src={blog.image.src} style={{ width: "100%" }} alt={blog.title} title={blog.title} />
          </Box>
          {hover && (
            <Box className={classes.hoverContainer} sx={{ height: "100px" }} px="24px">
              <Flex sx={{ flex: "1", color: "white", fontSize: ["12pt", "16pt"] }} justifyContent="flex-end" mr="32px">
                {t("Posted")}{" "}
                {new Intl.DateTimeFormat(locale, {
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                }).format(Date.parse(blog.publishedAt))}
              </Flex>
              <Flex sx={{ height: "100%", overflow: "hidden", color: "white" }} alignItems="center">
                <Box sx={{ border: "1px solid white" }} p="4px 16px">
                  <ArrowForward />
                </Box>
              </Flex>
            </Box>
          )}
          <div
            style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
          />
        </Link>
      </Box>
      <BlogTitleAndAuthor blog={blog} />
    </Box>
  );
}

export default () => {
  const language = useLanguage();
  const { t } = useTranslation("common");

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // TODO: if there are ever more than 250 blogs, we would have to retrieve all of them then filter
    api.get(`/${language}/blogs/articles?blog_id=2971&limit=250`).then((res) => {
      const locale = language === "en" ? "en_US" : "fr_CA";
      const results = res.data.blogArticles.filter((x) => x.blog.resource.id === 2971 && x.language.locale === locale);
      console.log(results);
      setBlogs(results);
    });
  }, [language]);

  return (
    <>
      <Navbar />
      <H2 title={t("Blogs")} rightSide={true} mb={["32px", "72px"]} />
      <Container>
        {blogs.map((blog, index) => (
          <Blog key={index} blog={blog} locale={language} />
        ))}
      </Container>
    </>
  );
};
