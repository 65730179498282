import React, { useState, useEffect } from "react";
import { Flex, Box } from "reflexbox";
import { useTheme } from "emotion-theming";
import { makeStyles } from "@material-ui/core/styles";
import { Input, Select, MenuItem, FormControl, InputLabel, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ArrowForward } from "@material-ui/icons";
import { api } from "../../api";
import { HR } from "../hr";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  title: {
    color: ({ theme }) => theme.colors.primary,
    fontWeight: "bold",
    fontSize: "16pt",
    textAlign: "center"
  },
  button: {
    cursor: "pointer",
    border: "1px solid #707070",
    padding: "8px 12px",
    color: ({ theme }) => theme.colors.primary,
    fontWeight: "bold",
    fontSize: "13pt",
    backgroundColor: "transparent",
    display: "flex",
    width: "100%",
    alignItems: "center"
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function handleEvent(func) {
  return (event) => {
    func(event.target.value);
  };
}

export function NewsletterForm(props) {
  const width = props.width || "380px";

  const { t } = useTranslation("newsletter");

  const professions = [
    { value: "Designer", text: t("Designer") },
    { value: "Architect", text: t("Architect") },
    { value: "Engineer", text: t("Engineer") },
    { value: "Distributor", text: t("Distributor") },
    { value: "Lighting Designer", text: t("Lighting Designer") },
    { value: "Cooperative", text: t("Cooperative") },
    { value: "Electrician", text: t("Electrician") },
    { value: "Cabinetmaker", text: t("Cabinetmaker") },
    { value: "Manufacturer", text: t("Manufacturer") },
    { value: "Other", text: t("Other") }
  ];

  const buttonStyle = props.buttonStyle;
  const onSuccess = props.onSuccess;
  const stayOpen = props.stayOpen;

  const [showForm, setShowForm] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [profession, setProfession] = useState("");

  useEffect(() => {
    setShowForm((email && email.trim().length !== 0) || stayOpen);
  }, [email, stayOpen]);

  const submitForm = (event) => {
    event.preventDefault();
    api
      .post("/newsletter/signup", {
        email,
        firstName,
        lastName,
        companyName,
        profession,
        toAddress: "info@lumigroup.com"
      })
      .then((res) => {
        onSuccess();
      })
      .catch((err) => {
        setShowErrorAlert(true);
      });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorAlert(false);
  };

  return (
    <>
      <form style={{ width }} onSubmit={submitForm}>
        <Flex flexDirection="column" justifyContent="center">
          <FormControl>
            <InputLabel>{t("form.Email")}</InputLabel>
            <Input
              required
              type="email"
              value={email}
              onChange={handleEvent(setEmail)}
              onFocus={() => {
                setShowForm(true);
              }}
              onBlur={() => {
                if ((!email || email.trim().length === 0) && !stayOpen) {
                  setShowForm(false);
                }
              }}
            />
          </FormControl>
          {showForm && (
            <Flex flexDirection="column">
              <Flex mt="8px">
                <Box mr="8px" flex="1">
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel>{t("form.First Name")}</InputLabel>
                    <Input value={firstName} onChange={handleEvent(setFirstName)} type="text" required />
                  </FormControl>
                </Box>
                <Box ml="8px" flex="1">
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel>{t("form.Last Name")}</InputLabel>
                    <Input value={lastName} onChange={handleEvent(setLastName)} type="text" required />
                  </FormControl>
                </Box>
              </Flex>
              <FormControl style={{ marginTop: "8px" }}>
                <InputLabel>{t("form.Company Name")}</InputLabel>
                <Input value={companyName} onChange={handleEvent(setCompanyName)} type="text" required />
              </FormControl>
              <FormControl style={{ marginTop: "8px" }}>
                <InputLabel>{t("form.Profession")}</InputLabel>
                <Select value={profession} onChange={handleEvent(setProfession)} required>
                  {professions.map((profession, index) => (
                    <MenuItem key={index} value={profession.value}>
                      {profession.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box mt="32px">
                <button className={buttonStyle}>
                  <Flex flex="1" justifyContent="center">
                    {t("form.subscribe-button")}
                  </Flex>
                  <ArrowForward style={{ color: "#484848" }} />
                </button>
              </Box>
            </Flex>
          )}
        </Flex>
      </form>
      <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          {t("form.unknown-error-message")}
        </Alert>
      </Snackbar>
    </>
  );
}

export function Newsletter() {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const { t } = useTranslation("newsletter");

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  return (
    <Box>
      <HR color={theme.colors.primary} />
      <Flex mt="128px" flexDirection="column" alignItems="center">
        <span className={classes.title}>{showSuccessAlert ? t("thanks-for-joining") : t("stay-up-to-date")}</span>
        {showSuccessAlert || (
          <Box mt="40px">
            <NewsletterForm
              buttonStyle={classes.button}
              onSuccess={() => {
                setShowSuccessAlert(true);
              }}
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
}
